// src/Router.js
import React, { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from '../pages/HomePage';
import AboutPage from '../pages/AboutPage';
import VardiutlandetPage from '../pages/Vard-i-utlandet';
import CascaisPage from '../pages/CascaisPage';
import VaccinPage from '../pages/VaccinPage';
import CannesPage from '../pages/CannesPage';
import CookiePage from '../pages/CookiePage';
import MandelieuPage from '../pages/MandelieuPage';
import DraguignanPage from '../pages/DraguignanPage';
function AppRouter() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/cook" element={<CookiePage />} />
                <Route path="/vard" element={<VardiutlandetPage />} />
                <Route path="/casc" element={<CascaisPage />} />
                <Route path="/vacc" element={<VaccinPage />} />
                <Route path="/mark" element={<CannesPage />} />
                <Route path="/mandelieu" element={<MandelieuPage />} />
                <Route path="/draguignan" element={<DraguignanPage />} />
            </Routes>
        </Router>
    );
}

export default AppRouter;