import React, { useState, useEffect } from "react";
import { initializeAnalytics } from '../components/analytics';
import { getCookie } from "./getCookie";


function CookieBanner() {
    useEffect(() => {
        const necessary = getCookie("necessary");
        const statistics = getCookie("statistics");

        /*         console.log("Necessary:", necessary);
                console.log("Statistics:", statistics); */

        if (necessary === "true") {
            // Dölj bannern om användaren redan har accepterat
            setShowBanner(false);
        }
    }, []);


    const [showBanner, setShowBanner] = useState(true);

    const setCookie = (name, value, days) => {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        const expires = `expires=${date.toUTCString()}`;
        const sameSite = "SameSite=None; Secure"; // Ändra till "None; Secure" i produktion med HTTPS
        document.cookie = `${name}=${value}; ${expires}; ${sameSite}; path=/`;
    };


    const handleAcceptAll = () => {
        setCookie("necessary", "true", 365);
        setCookie("statistics", "true", 365);
        initializeAnalytics();
        setShowBanner(false);
    };

    const handleAcceptNecessary = () => {
        setCookie("necessary", "true", 365);
        setCookie("statistics", "false", 365);
        setShowBanner(false);
    };

    if (!showBanner) return null;

    return (
        <div className="fixed top-12 left-0 w-full bg-gray-800 text-slate-700 p-4 flex flex-col md:flex-row justify-between items-center z-50">
            <p className="mb-2 md:mb-0">
                Vi använder cookies för att förbättra din upplevelse. Läs vår{"  "}
                <a href="/cook" className="underline">
                    Cookiepolicy
                </a>.
            </p>
            <div className="flex gap-4">
                <button
                    onClick={handleAcceptAll}
                    className="bg-blue-500 hover:bg-blue-600 text-slate-700 py-2 px-4 rounded"
                >
                    Acceptera alla
                </button>
                <button
                    onClick={handleAcceptNecessary}
                    className="bg-gray-500 hover:bg-gray-600 text-slate-700 py-2 px-4 rounded"
                >
                    Endast nödvändiga
                </button>
            </div>
        </div>
    );
}

export default CookieBanner;
