import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Layout from '../components/Layout';


function CannesPage() {
    const markdownContent = `
![Marknaden i Cannes](/images/draguignan-terrace.jpg)

**Vårt radhus i Draguignan: En doft av Var**  

Draguignan, en charmig stad i regionen Var, är som ett levande vykort från Provence. Med sina smala gränder kantade av pastellfärgade hus, små torg med porlande fontäner och doften av lavendel i luften, fångar den den provensalska livsstilen på ett unikt sätt. Stadens hjärta, Place du Marché, fylls av liv under de lokala marknaderna, där man kan köpa färsk frukt, nybakat bröd och lokala specialiteter som olivolja och honung. 

Draguignan har också en rik historia. Här kan man vandra bland ruiner från romartiden och besöka stadens landmärke, Le Dolmen de la Pierre de la Fée, en mystisk stenkonstruktion som tros vara flera tusen år gammal. Området erbjuder även fantastisk natur med närhet till Gorges du Verdon, där kristallklara floder och dramatiska klippväggar bjuder in till vandring och kanotpaddling.

Trots sin historiska charm har Draguignan modern service och en lugn atmosfär som lockar såväl besökare som nya invånare. Det är en stad där livet går i ett behagligt tempo, där man kan njuta av Provence utan storstadens stress, men ändå ha nära till kusten och dess glittrande stränder.
`;
    return (
        <Layout>
            <div>
                <ReactMarkdown
                    children={markdownContent}
                    remarkPlugins={[remarkGfm]}
                    components={{
                        h1: ({ children }) => <h1 className="text-4xl font-bold mb-4">{children}</h1>,
                        h2: ({ children }) => <h2 className="text-3xl font-bold mb-4">{children}</h2>,
                        h3: ({ children }) => <h3 className="text-2xl font-semibold mb-4">{children}</h3>,
                        p: ({ children }) => <p className="mb-4 text-xl">{children}</p>,
                        blockquote: ({ children }) => <blockquote className='p-3'>{children}</blockquote>,
                        table: ({ children }) => (
                            <table className="table-auto w-full border-collapse border border-gray-400">
                                {children}
                            </table>
                        ),
                        th: ({ children }) => (
                            <th className="border border-gray-700 px-4 py-2 bg-gray-200 text-left">
                                {children}
                            </th>
                        ),
                        td: ({ children }) => (
                            <td className="border border-gray-700 px-4 py-2">
                                {children}
                            </td>
                        ),
                        img: ({ children }) => (
                            <img src='/images/draguignan-terrace.jpg' className="rounded" height="500px" width="600px" alt="Usikt från vår terass!">
                                {children}
                            </img>
                        ),
                    }}
                />
            </div>
        </Layout>
    );
}
export default CannesPage;
