import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Layout from '../components/Layout';

function AboutPage() {
    const markdownContent = `
![Forfattaren](/images/me-at-79.jpg)

### Jag heter Ulf

Jag och min hustru Viveca har haft förmånen att bo i två fantastiska länder under en stor del av våra liv. 

I Frankrike där vi tillbringade tretton år av våra liv och i
Portugal där vi tillbringade fem år.

### Tack för att du besöker min site! 
`;
    return (
        <Layout>
            <div>
                <ReactMarkdown
                    children={markdownContent}
                    remarkPlugins={[remarkGfm]}
                    components={{
                        h1: ({ children }) => <h1 className="text-4xl font-bold mb-4">{children}</h1>,
                        h2: ({ children }) => <h2 className="text-3xl font-bold mb-4">{children}</h2>,
                        h3: ({ children }) => <h3 className="text-2xl font-semibold mb-4">{children}</h3>,
                        p: ({ children }) => <p className="mb-4 text-xl">{children}</p>,
                        blockquote: ({ children }) => <blockquote className='p-3'>{children}</blockquote>,
                        table: ({ children }) => (
                            <table className="table-auto w-full border-collapse border border-gray-400">
                                {children}
                            </table>
                        ),
                        th: ({ children }) => (
                            <th className="border border-gray-700 px-4 py-2 bg-gray-200 text-left">
                                {children}
                            </th>
                        ),
                        td: ({ children }) => (
                            <td className="border border-gray-700 px-4 py-2">
                                {children}
                            </td>
                        ),
                        img: ({ children }) => (
                            <img src='/images/me-at-79.jpg' className="rounded" alt="En lite justerad bild av mig!">
                                {children}
                            </img>
                        ),
                    }}
                />
            </div>
        </Layout>
    );
}
export default AboutPage;
