import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Layout from '../components/Layout';


function CannesPage() {
    const markdownContent = `
![Marknaden i Cannes](/images/Forville-2013-12-27.jpg)

**Forville-marknaden i Cannes: En doft av Provence**  

Mitt i hjärtat av Cannes, bara ett stenkast från den glittrande Croisetten, ligger Marché Forville – en plats där Provence vaknar till liv i sin fulla prakt. Här möts du av färgsprakande grönsaker, doften av nybakade baguetter och den vänliga sorlen från handlare och lokalbor.  

Marknaden är mer än bara en plats att handla på – den är en upplevelse för alla sinnen. Lägg märke till olivstånden med rader av gyllene olivolja och provsmakning av tapenade. Doften av lavendel från tvåltillverkarna påminner om varma sommarkvällar, medan de lokala ostarna och charkuterierna erbjuder en smak av det genuina Sydfrankrike.  

Passa på att prata med handlarna – många har odlat eller producerat sina varor själva, och de delar gärna med sig av recept och tips. Köp med dig en saftig melon, en bit chèvre och kanske ett par saftiga tomater, så har du en piknik redo att avnjutas på stranden eller i en närliggande park.  

Att besöka Forville-marknaden är att stiga in i en annan värld, där traditioner och lokala smaker står i centrum. Oavsett om du är turist eller bosatt, är detta en plats som förtrollar och inspirerar varje gång du kommer dit.
`;
    return (
        <Layout>
            <div>
                <ReactMarkdown
                    children={markdownContent}
                    remarkPlugins={[remarkGfm]}
                    components={{
                        h1: ({ children }) => <h1 className="text-4xl font-bold mb-4">{children}</h1>,
                        h2: ({ children }) => <h2 className="text-3xl font-bold mb-4">{children}</h2>,
                        h3: ({ children }) => <h3 className="text-2xl font-semibold mb-4">{children}</h3>,
                        p: ({ children }) => <p className="mb-4 text-xl">{children}</p>,
                        blockquote: ({ children }) => <blockquote className='p-3'>{children}</blockquote>,
                        table: ({ children }) => (
                            <table className="table-auto w-full border-collapse border border-gray-400">
                                {children}
                            </table>
                        ),
                        th: ({ children }) => (
                            <th className="border border-gray-700 px-4 py-2 bg-gray-200 text-left">
                                {children}
                            </th>
                        ),
                        td: ({ children }) => (
                            <td className="border border-gray-700 px-4 py-2">
                                {children}
                            </td>
                        ),
                        img: ({ children }) => (
                            <img src='/images/Forville-2013-12-27.jpg' className="rounded" height="500px" width="600px" alt="En marknad i Cannes!">
                                {children}
                            </img>
                        ),
                    }}
                />
            </div>
        </Layout>
    );
}
export default CannesPage;
